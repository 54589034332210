import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

import EntryFooter from 'components/Post/EntryFooter'

const URL = process.env.GATSBY_WP_URL

function replaceAll (str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export default ({ data: { wordpress: { post, prev, next } } }) => {
  const content = replaceAll(post.content, `href="${URL}`, 'href="')

  return (
    <Layout>
      <article id={`post-${post.postId}`} className="post-1 post type-post status-publish format-standard hentry category-uncategorized entry">
        <header className="entry-header">
          <h2 className="entry-title">
            {post.title}
          </h2>
        </header>
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: content }} />
        <EntryFooter post={post} isSingle />
        <div className="author-bio">
          <h2 className="author-title">
            <span className="author-heading">Published by {post.author.name}</span>{' '}
          </h2>
          <p className="author-description">
            {post.author.description}
            <Link className="author-link" to={`/author/${post.author.slug}/`} rel="author">View more posts</Link>
          </p>
        </div>
      </article>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Post navigation</h2>
        <div className="nav-links">
          {prev && prev.id !== post.id &&
            <div className="nav-previous">
              <Link to={`/${prev.slug}`} rel="prev">
                <span className="meta-nav" aria-hidden="true">Previous Post</span> <span className="screen-reader-text">Previous Post:</span> <br /><span className="post-title">{prev.title}</span>
              </Link>
            </div>
          }
          {next && next.id !== post.id &&
            <div className="nav-next">
              <Link to={`/${next.slug}`} rel="next">
                <span className="meta-nav" aria-hidden="true">Next Post</span> <span className="screen-reader-text">Next post:</span> <br /><span className="post-title">{next.title}</span>
              </Link>
            </div>
          }
        </div>
      </nav>
    </Layout>
  )
}
export const query = graphql`
  query($id: ID!, $nextId: ID!, $prevId: ID!) {
    wordpress {
      post(id: $id) {
        id
        postId
        slug
        title
        content
        date
        dateGmt
        modified
        modifiedGmt
        commentCount
        author {
          name
          slug
          description
        }
        tags {
          edges {
            node {
              slug
              name
            }
          }
        }
        categories {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
      next: post(id: $nextId) {
        id
        slug
        title
      }
      prev: post(id: $prevId) {
        id
        slug
        title
      }
    }
  }
`
